import React from 'react';

export const Loading = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-[#12225857] w-full absolute z-10">
      <div className="animate-spin">
        <svg
          width="80"
          height="80"
          viewBox="0 0 80 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="01-General/loading">
            <mask id="mask0_4561_3" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="80" height="80">
              <g id="mask-2">
                <path
                  id="Vector"
                  d="M49.0909 6.95652C49.0909 16.2319 34.5455 16.2319 34.5455 6.95652C34.5455 -2.31884 49.0909 -2.31884 49.0909 6.95652ZM45.4545 76.5217C45.4545 81.1594 38.1818 81.1594 38.1818 76.5217C38.1818 71.8841 45.4545 71.8841 45.4545 76.5217ZM29.3947 8.40502C33.8392 15.7728 22.2917 22.1496 17.8473 14.7834C13.4028 7.42184 24.9503 1.04502 29.3947 8.40502ZM62.6327 70.4294C64.6529 73.7809 59.4069 76.6794 57.3867 73.3279C55.3665 69.9826 60.6125 67.0841 62.6327 70.4294ZM13.3463 19.3484C20.341 23.2131 14.2804 33.2553 7.28566 29.3905C0.284445 25.5258 6.34505 15.4837 13.3463 19.3484ZM74.5325 57.1069C77.3301 58.6528 74.9059 62.669 72.1083 61.1231C69.3059 59.5772 71.7301 55.561 74.5325 57.1069ZM10.9091 41.7391C10.9091 48.6957 0 48.6957 0 41.7391C0 34.7826 10.9091 34.7826 10.9091 41.7391ZM80 41.7391C80 44.058 76.3636 44.058 76.3636 41.7391C76.3636 39.4203 80 39.4203 80 41.7391ZM14.5018 56.824C17.7341 62.1743 9.33657 66.812 6.10424 61.4616C2.87192 56.1051 11.2695 51.4674 14.5018 56.824ZM74.3758 23.7557C75.1838 25.096 73.0877 26.2555 72.2796 24.9152C71.4715 23.5749 73.5677 22.4155 74.3758 23.7557ZM25.7762 68.3579C30.6747 71.0632 26.4323 78.0924 21.5337 75.3871C16.6352 72.6817 20.8776 65.6526 25.7762 68.3579Z"
                  fill="white"
                />
              </g>
            </mask>
            <g mask="url(#mask0_4561_3)">
              <g id="Group">
                <g id="Base/Color/General/Grey">
                  <path id="Rectangle" d="M88 -8H-8V88H88V-8Z" fill="white" />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};


